import React from 'react';
import SocialLogin from '../Components/SocialLogin';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './Login.css';
import AuthService from '../Service/AuthService';
import * as yup from 'yup';
import CustomLink from '../Components/CustomLink';
import Separator from '../Components/Separator';
import Loader from '../Components/Loader';
import FormError from '../Components/FormError';
import Validation from '../Util/Validation';
import ErrorMessages from '../Util/ErrorMessages';
import UriHelper from '../Util/UriHelper';
import Box from '../Components/Box';
import Logos from '../Components/Logos';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: '',
      params: {},
      params2: {},
      facebookUrl: '',
      googleUrl: '',
      appleUrl: '',
      username: '',
      password: '',
      passwordResetRequired: false,
      passwordResetUsername: '',
      passwordResetSession: '',
      showPassword: false
    };

    this.renderForm = this.renderForm.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.showPassword = this.showPassword.bind(this);
  }

  async componentDidMount() {
    const params2 = UriHelper.URLSearchParams(this.props.location.search);
    this.setState({
      params2: params2,
    });
    const params = UriHelper.getParams(this.props.location.search);
    this.setState({
      params: params,
      username: params.email || '',
    });

    try {
      const isCodeResponse = params.responseType === 'code';
      const { data } = isCodeResponse
        ? await AuthService.createCode(params2)
        : await AuthService.createCredentials(params);

      if (data.status === 'AUTHENTICATED') {

        if (isCodeResponse) {
          this.redirectWithCode(data.code);
        } else {
          this.redirectWithToken(data.accessToken, data.idToken);
        }

      } else {
        this.setState({
          loaded: true,
          facebookUrl: data.facebookUrl,
          googleUrl: data.googleUrl,
          appleUrl: data.appleUrl,
        });
      }
    } catch (error) {
      const uri = UriHelper.error({ code: error.response.status });
      this.props.history.push(uri);
    }
  }

  showPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    return (
      <Box pageTitle="Login"
        title="Conta"
        description="Uma só conta para todos os produtos NSC"
        logo={true}
        >
        <Logos />
        <Formik
          onSubmit={this.onFormSubmit}
          render={this.renderForm}
          initialValues={{
            username: this.state.username,
            password: this.state.password,
            newPassword: '',
            passwordResetRequired: this.state.passwordResetRequired,
          }}
          validationSchema={this.getFormValidationSchema}
          enableReinitialize={true} />
        <div className="links-container">
          <div className='link-wrapper'>
            <div className='link-wrapper-text'>Ainda não tem conta?&nbsp;</div>
            <CustomLink
              linkText="Cadastre-se"
              size="1.3rem"
              inline={true}
              to={UriHelper.signupLink(this.state.params)}
              className="link-cadastre" />
          </div>
        </div>

        <Separator />

         <div className="links-container">
          <div style={{ fontSize: '0.9rem' }} className='link-wrapper'>
            <div className='link-wrapper-text'>Você é assinante e não tem cadastro?&nbsp;</div>
            <CustomLink
              linkText="Clique aqui"
              size="0.9rem"
              inline={true}
              to={UriHelper.signupFullLink(this.state.params)}
              className="link-assinante" />
          </div>
        </div>

        <Separator text={<b>ou entre com</b>} />

        <SocialLogin
          facebookUrl={this.state.facebookUrl}
          googleUrl={this.state.googleUrl}
          appleUrl={this.state.appleUrl}
          style={{ paddingBottom: '0' }} />
        <Loader loaded={this.state.loaded}></Loader>

        <Separator /> 

        <div className="call-us">Fale conosco pelo WhatsApp</div>

        <div className="whatsapp">

          <a className="link-whatsapp" href="https://api.whatsapp.com/send?phone=5508006444001 " target="_blank" rel="noreferrer noopener">
            Clique aqui ou envie uma mensagem para 0800 644 4001    
          </a>

        </div>

      </Box>
    );
  }


  renderForm({ errors, touched, isSubmitting }) {
    const passwordInput = ({ field, form, ...props }) => {
      return (
        <div>
          {form.errors.password && <div className="error-message">{form.errors.password}</div>}
          <div style={{ marginBottom: '3px' }} className={`input-pass ${!!form.errors.password ? 'error' : ''}`}>
            <input
              className='pass'
              name={field.name}
              type={`${this.state.showPassword ? 'text' : 'password'}`}
              value={field.value}
              tabIndex="2"
              placeholder="Digite sua senha"
              onChange={e => form.setFieldValue(field.name, e.target.value)} />
            {!this.state.showPassword ?
              <svg onClick={this.showPassword} aria-hidden="true" fill="currentColor" focusable="false" width="24px" height="24px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg"><path d="M10.58,7.25l1.56,1.56c1.38,0.07,2.47,1.17,2.54,2.54l1.56,1.56C16.4,12.47,16.5,12,16.5,11.5C16.5,9.02,14.48,7,12,7 C11.5,7,11.03,7.1,10.58,7.25z"></path><path d="M12,6c3.79,0,7.17,2.13,8.82,5.5c-0.64,1.32-1.56,2.44-2.66,3.33l1.42,1.42c1.51-1.26,2.7-2.89,3.43-4.74 C21.27,7.11,17,4,12,4c-1.4,0-2.73,0.25-3.98,0.7L9.63,6.3C10.4,6.12,11.19,6,12,6z"></path><path d="M16.43,15.93l-1.25-1.25l-1.27-1.27l-3.82-3.82L8.82,8.32L7.57,7.07L6.09,5.59L3.31,2.81L1.89,4.22l2.53,2.53 C2.92,8.02,1.73,9.64,1,11.5C2.73,15.89,7,19,12,19c1.4,0,2.73-0.25,3.98-0.7l4.3,4.3l1.41-1.41l-3.78-3.78L16.43,15.93z M11.86,14.19c-1.38-0.07-2.47-1.17-2.54-2.54L11.86,14.19z M12,17c-3.79,0-7.17-2.13-8.82-5.5c0.64-1.32,1.56-2.44,2.66-3.33 l1.91,1.91C7.6,10.53,7.5,11,7.5,11.5c0,2.48,2.02,4.5,4.5,4.5c0.5,0,0.97-0.1,1.42-0.25l0.95,0.95C13.6,16.88,12.81,17,12,17z"></path></svg> :
              <svg onClick={this.showPassword} aria-hidden="true" fill="currentColor" focusable="false" width="24px" height="24px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg"><path d="M12,7c-2.48,0-4.5,2.02-4.5,4.5S9.52,16,12,16s4.5-2.02,4.5-4.5S14.48,7,12,7z M12,14.2c-1.49,0-2.7-1.21-2.7-2.7 c0-1.49,1.21-2.7,2.7-2.7s2.7,1.21,2.7,2.7C14.7,12.99,13.49,14.2,12,14.2z"></path><path d="M12,4C7,4,2.73,7.11,1,11.5C2.73,15.89,7,19,12,19s9.27-3.11,11-7.5C21.27,7.11,17,4,12,4z M12,17 c-3.79,0-7.17-2.13-8.82-5.5C4.83,8.13,8.21,6,12,6s7.17,2.13,8.82,5.5C19.17,14.87,15.79,17,12,17z"></path></svg>}
          </div>
        </div>
      );
    };

    return (
      <Form className="form">
        <div className="form-group">
          <label>E-mail*</label>
          <ErrorMessage name="username" component="div" className="error-message" />
          <Field
            type="text"
            name="username"
            autoCorrect="off"
            autoCapitalize="off"
            tabIndex="1"
            placeholder="Digite seu e-mail"
            disabled={this.state.passwordResetRequired}
            className={errors.username && touched.username ? 'error text-lower-case' : 'text-lower-case'} />
          <CustomLink
            linkText="Esqueceu o e-mail da sua conta NSC?"
            size="0.8rem"
            align="right"
            to={UriHelper.forgotEmailLink(this.state.params)} />
        </div>
        <div className="form-group">
          <label>Senha*</label>
          {/* <Field
            type="password"
            name="password"
            tabIndex="2"
            placeholder="Digite sua senha"
            disabled={this.state.passwordResetRequired}
            className={errors.password && touched.password ? 'error' : ''} /> */}

          <Field
            name="password"
            disabled={this.state.passwordResetRequired}
            className={errors.password && touched.password ? 'error' : ''}
            render={passwordInput} />
          <CustomLink
            linkText="Esqueceu a senha?"
            size="0.8rem"
            align="right"
            to={UriHelper.forgotPasswordLink(this.state.params)} />
        </div>
        <div hidden={!this.state.passwordResetRequired} className="form-group">
          <h2 style={{ margin: '35px 0 15px' }}>Você precisa alterar sua senha.</h2>
          <label>Nova senha</label>
          <ErrorMessage name="newPassword" component="div" className="error-message" />
          <Field
            type="password"
            name="newPassword"
            placeholder="Digite sua nova senha"
            autoComplete="off"
            className={errors.newPassword && touched.newPassword ? 'error' : ''} />
        </div>
        <Field type="hidden" name="passwordResetRequired" />
        <FormError message={this.state.error} />
        <button tabIndex="3" className="btn-submit" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : this.state.passwordResetRequired ? 'Alterar senha' : 'Entrar'}
        </button>
      </Form>
    );
  }

  getFormValidationSchema() {
    return yup.object().shape({
      username: Validation.email({ required: true }),
      password: Validation.password({ required: true }),
      passwordResetRequired: Validation.any({ required: false }),
      newPassword: yup.string().when("passwordResetRequired", {
        is: 'true',
        then: Validation.password({ required: true }),
      }),
    });
  }

  async onFormSubmit(values, { setSubmitting }) {
    let result = null;

    values.username = values.username.trim();

    try {
      // result = await AuthService.login({
      //   username: ("thiago.machado@nsc.com.br").toLowerCase(),
      //   password: "A1s5d9f8",
      //   resetSession: this.state.passwordResetSession,
      //   clientId: this.state.params.clientId,
      //   redirectUri: this.state.params.redirectUri,
      //   responseType: this.state.params.responseType.toUpperCase(),
      // });
      result = await AuthService.login({
        username: (values.username || this.state.passwordResetUsername).toLowerCase(),
        password: this.state.passwordResetRequired ? values.newPassword : values.password,
        resetSession: this.state.passwordResetSession,
        clientId: this.state.params.clientId,
        redirectUri: this.state.params.redirectUri,
        responseType: this.state.params.responseType.toUpperCase(),
      });
    } catch (error) {
      this.setState({
        username: values.username,
        password: values.password,
        error: 'Ocorreu um erro.',
      });
      return;
    } finally {
      setSubmitting(false);
    }

    if (result && result.data.status === 'AUTHENTICATED') {

      if (this.state.params.responseType === 'code') {
        this.redirectWithCode(result.data.code);
      } else {
        this.redirectWithToken(result.data.accessToken, result.data.idToken);
      }

      return;
    }

    if (result && result.data.status === 'NEW_PASSWORD_REQUIRED') {
      if (result.data.resetSession) {
        this.setState({
          username: values.username,
          password: values.password,
          passwordResetRequired: true,
          passwordResetUsername: values.username,
          passwordResetSession: result.data.resetSession,
          error: '',
        });
      }

      return;
    }

    this.setState({
      username: values.username,
      password: values.password,
      error: ErrorMessages.getDescription(result ? result.data.status : null),
    });
  }

  redirectWithCode(code) {
    const uri = UriHelper.buildUri(this.state.params.redirectUri, { code, state: encodeURIComponent(this.state.params2.state+'?&_gl='+this.state.params2._gl) });
    window.location.href = uri;
  }

  redirectWithToken(accessToken, idToken) {
    const uri = UriHelper.buildUri(this.state.params.redirectUri, { accessToken, idToken, state: encodeURIComponent(this.state.params2.state+'?&_gl='+this.state.params2._gl) });
    window.location.href = uri;
  }
}

export default Login;
